






import { defineComponent } from '@vue/composition-api';
import { useGeneral } from '@/module/general';

export default defineComponent({
  name: 'AppHome',
  setup() {
    const { appName } = useGeneral();
    return {
      appName
    };
  }
});
