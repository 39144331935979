import { render, staticRenderFns } from "./AppNavDrawer.vue?vue&type=template&id=5f50db80&"
import script from "./AppNavDrawer.vue?vue&type=script&lang=js&"
export * from "./AppNavDrawer.vue?vue&type=script&lang=js&"
import style0 from "./AppNavDrawer.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VDivider,VLayout,VList,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/Volumes/ARCHIVE/Source/five9/ps-droids/packages/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5f50db80')) {
      api.createRecord('5f50db80', component.options)
    } else {
      api.reload('5f50db80', component.options)
    }
    module.hot.accept("./AppNavDrawer.vue?vue&type=template&id=5f50db80&", function () {
      api.rerender('5f50db80', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/app/AppNavDrawer.vue"
export default component.exports