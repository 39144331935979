<template>
  <v-app>
    <v-overlay :absolute="false" :value="wait" :opacity="0.75" z-index="999">
      <v-progress-circular
        :size="150"
        :width="10"
        color="#eeeeff"
        indeterminate
      ></v-progress-circular>
    </v-overlay>

    <AppNavDrawer />
    <AppTopBar />

    <v-main>
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
// import Chat from '@/components/chat/Chat.vue';
// import io from 'socket.io-client';

import AppTopBar from '@/layouts/app/AppTopBar.vue';
import AppNavDrawer from '@/layouts/app/AppNavDrawer.vue';

// UI Composable
import { useUi } from '@/module/ui';

export default defineComponent({
  name: 'AppLayout',
  components: {
    AppTopBar,
    AppNavDrawer
    // Chat
  },

  setup() {
    const { wait, waitToggle } = useUi();

    return { wait, waitToggle };
  }
});
</script>
