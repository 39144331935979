import axios from 'axios';
import Vue from 'vue';
import '@/lib/log';

import { reactive, computed, toRefs } from '@vue/composition-api';
import { AppState, SysConfigModel, UseGeneral } from './general.interface';

const newState: AppState = {
  appName: 'Droids',
  appLogoFile: 'five9-logo-white.svg',
  appVersion: process.env.VUE_APP_VERSION,
  appVersionName: process.env.VUE_APP_VERSION_NAME,
  appVersionHash: process.env.VUE_APP_VERSION_HASH,
  appNameWithVersionHash: ''
};
const newSysconfig: SysConfigModel = {};

const state = reactive({ ...newState });
const sysConfigState = reactive({ ...newSysconfig });

export function useGeneral(): UseGeneral {
  //@ts-ignore
  const getSysconfig = async (): SysConfigModel => {
    try {
      Vue.$log.debug('Loading appConfig data');

      const res = await axios.get('appconfig');
      Vue.$log.debug('Returned from appconfig API: ', res.data);
      if (res.data) {
        if (res.data['sys']) {
          const newSysconfig = res.data['sys'];
          Vue.$log.debug(
            'system node found in appconfig result. Assigning to sysConfig state.',
            newSysconfig
          );
          Object.assign(sysConfigState, newSysconfig);
          return res.data['sys'];
        }
      }
    } catch (err) {
      Vue.$log.error('Error loading appConfig', err);
    }
  };

  return {
    appName: computed(() => state.appName),
    appLogoFile: computed(() => require(`@/assets/images/${state.appLogoFile}`)),
    appVersion: computed(() => state.appVersion),
    appVersionName: computed(() => state.appVersionName),
    appVersionHash: computed(() => state.appVersionHash),
    appNameWithVersionHash: computed(() => {
      return `${state.appVersionName}/${state.appVersion}-${state.appVersionHash}`;
    }),
    sysConfig: { ...toRefs(sysConfigState) },
    getSysconfig
  };

  // return {
  //   appName: computed(() => state.appName),
  //   appLogoFile: require(`@/assets/images/${state.appLogoFile}`),
  //   appVersion: computed(() => state.appVersion),
  //   appVersionName: computed(() => state.appVersionName),
  //   appVersionHash: computed(() => state.appVersionHash),
  //   sysConfig: state.sysConfig,
  //   getSysconfig
  // };
}
