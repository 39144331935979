var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm._l(_vm.data, function(item, i) {
        return [
          item.heading
            ? _c(
                "v-row",
                { key: item.heading, attrs: { align: "center" } },
                [
                  _c("v-col", { attrs: { cols: "6" } }, [
                    _c(
                      "span",
                      {
                        staticClass: "text-body-1 subheader pl-4",
                        class: item.heading && _vm.drawer ? "show " : "hide"
                      },
                      [_vm._v(" " + _vm._s(item.heading) + " ")]
                    )
                  ]),
                  _c("v-spacer", {
                    class: item.heading && _vm.drawer ? "show " : "hide"
                  })
                ],
                1
              )
            : item.divider
            ? _c("v-divider", {
                key: i,
                staticClass: "my-4",
                attrs: { dark: "" }
              })
            : item.children && _vm.drawer
            ? _c(
                "v-list-group",
                {
                  key: item.title,
                  attrs: { color: "primary", "append-icon": "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "prependIcon",
                        fn: function() {
                          return [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  color: item.iconColor ? item.iconColor : ""
                                }
                              },
                              [_vm._v(_vm._s(item.icon))]
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "activator",
                        fn: function() {
                          return [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(" " + _vm._s(item.title) + " ")
                                ])
                              ],
                              1
                            )
                          ]
                        },
                        proxy: true
                      },
                      {
                        key: "appendIcon",
                        fn: function() {
                          return [_c("v-icon", [_vm._v("mdi-menu-down")])]
                        },
                        proxy: true
                      }
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: item.model,
                    callback: function($$v) {
                      _vm.$set(item, "model", $$v)
                    },
                    expression: "item.model"
                  }
                },
                _vm._l(item.children, function(child, i) {
                  return _c(
                    "v-list-item",
                    {
                      key: i,
                      attrs: {
                        to:
                          child.route && child.route.name
                            ? child.route
                            : undefined,
                        link: child.route && child.route.name ? true : false,
                        exact: ""
                      },
                      on: {
                        click: function($event) {
                          child.click ? _vm.handleClick($event, child) : null
                        }
                      }
                    },
                    [
                      child.icon
                        ? _c(
                            "v-list-item-action",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: child.iconColor
                                      ? child.iconColor
                                      : ""
                                  }
                                },
                                [_vm._v(_vm._s(child.icon))]
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(" " + _vm._s(child.title) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c(
                "v-list-item",
                {
                  key: item.text,
                  attrs: {
                    color: "primary",
                    to: item.route && item.route.name ? item.route : undefined,
                    link: item.route && item.route.name ? true : false,
                    exact: ""
                  },
                  on: {
                    click: function($event) {
                      item.click ? _vm.handleClick($event, item) : null
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { color: item.iconColor ? item.iconColor : "" }
                        },
                        [_vm._v(_vm._s(item.icon))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-list-item-title", { attrs: { link: "" } }, [
                        _vm._v(" " + _vm._s(item.title) + " ")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }