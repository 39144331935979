<template>
  <div class="version">
    {{ version }}
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import { useGeneral } from '@/module/general';

export default defineComponent({
  setup() {
    const { appNameWithVersionHash } = useGeneral();
    return {
      version: appNameWithVersionHash
    };
  }
});
</script>

<style lang="scss">
.version {
  @import '~@/styles/theme/scss/variables';
  font-size: 10px;
  font-weight: 700;
  // color: $text-color;
}
</style>
