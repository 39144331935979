var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    { attrs: { "clipped-left": "", app: "", dark: "" } },
    [
      _c("v-app-bar-nav-icon", {
        on: {
          click: function($event) {
            return _vm.drawerToggle()
          }
        }
      }),
      _c("v-avatar", { staticClass: "ml-4 mr-2 mt-n2", attrs: { tile: "" } }, [
        _c("img", { attrs: { src: _vm.logo, alt: "Five9" } })
      ]),
      _c(
        "v-toolbar-title",
        { staticClass: "ml-0 pl-4", staticStyle: { width: "300px" } },
        [
          _c(
            "router-link",
            { staticClass: "home--link", attrs: { to: { name: "AppHome" } } },
            [
              _c("span", { staticClass: "hidden-sm-and-down" }, [
                _vm._v(_vm._s(_vm.appName))
              ])
            ]
          )
        ],
        1
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }