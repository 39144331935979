import 'roboto-fontface/css/roboto/roboto-fontface.css';
import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import VueCompositionAPI from '@vue/composition-api';

import router from '@/router';
import vuetify from './lib/vuetify';

import App from './App.vue';

import '@/lib/log';
import '@/lib/http';

// Reference App Base Components
import '@/components/_base/register-base';

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);

new Vue({
  router,
  vuetify,
  render: (h) => h(App)
}).$mount('#app');
