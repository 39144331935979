<template>
  <v-app>
    <v-container fluid>
      <v-card class="mx-auto" max-width="344" outlined>
        <v-img class="white--text align-start" :src="noAuthImage" alt="403">
          <v-card-title class="text-h4">403</v-card-title>
        </v-img>

        <v-card-text class="text-h6 black white--text text-center"
          >You don't have access to this.</v-card-text
        >
        <v-card-text class="text-xs text-overline text-right"
          ><a class="text-decoration-none" :to="{ name: 'Dashboard' }">Go Home →</a></v-card-text
        >
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'NotAuthorized',
  setup() {
    const noAuthImage = computed(() => {
      return require('@/assets/images/403.gif');
    });

    return { noAuthImage };
  }
});
</script>
