import { render, staticRenderFns } from "./_base-version.vue?vue&type=template&id=692a2088&"
import script from "./_base-version.vue?vue&type=script&lang=js&"
export * from "./_base-version.vue?vue&type=script&lang=js&"
import style0 from "./_base-version.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Volumes/ARCHIVE/Source/five9/ps-droids/packages/ui/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('692a2088')) {
      api.createRecord('692a2088', component.options)
    } else {
      api.reload('692a2088', component.options)
    }
    module.hot.accept("./_base-version.vue?vue&type=template&id=692a2088&", function () {
      api.rerender('692a2088', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/_base/_base-version.vue"
export default component.exports