var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: { "max-width": "344", outlined: "" }
            },
            [
              _c(
                "v-img",
                {
                  staticClass: "white--text align-start",
                  attrs: { src: _vm.notFoundImage, alt: "404" }
                },
                [
                  _c("v-card-title", { staticClass: "text-h4" }, [
                    _vm._v("404")
                  ])
                ],
                1
              ),
              _c(
                "v-card-text",
                { staticClass: "text-h6 black white--text text-center" },
                [_vm._v("We can't find that.")]
              ),
              _c(
                "v-card-text",
                { staticClass: "text-xs text-overline text-right" },
                [
                  _c(
                    "a",
                    {
                      staticClass: "text-decoration-none",
                      attrs: { to: { name: "Dashboard" } }
                    },
                    [_vm._v("Go Home →")]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }