var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { "text-align": "center" } }, [
    _c("img", {
      staticStyle: { width: "25%" },
      attrs: { src: _vm.maintenanceImage }
    }),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", [
      _vm._v("We are hard at work updating The Configurator."),
      _c("br"),
      _vm._v("Please check back soon!")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }