<template>
  <div>
    <MaintenanceMode />
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import MaintenanceMode from '@/views/MaintenanceMode.vue';

export default defineComponent({
  name: 'MaintenanceModeLayout',
  components: { MaintenanceMode }
});
</script>

<style lang="scss"></style>
