import { Ref } from '@vue/composition-api';

export const AuthRole = {
  Admin: 'Admin',
  User: 'User',
  Guest: 'Guest'
};

export interface AuthVerifyTokenRequest {
  portalToken: string;
  appId: string;
}
export interface AuthState {
  authMessage: string;
  authToken: string;
  authTokenExpires: number;
  authTokenExpiresIn: number;
  authTokenCreated: number;
  roles: string[];
  isAdmin: boolean;
  isUser: boolean;
  isAuthenticated: boolean;
  isAuthorized: boolean; // future use with individual roles
  portalUsername: string;
  portalUserId: string;
  portalDomainName: string;
  portalDomainId: string;
  portalSlug: string;
  organizationId?: string;
}
export interface UseAuth {
  authMessage: Ref<string>;
  authToken: Ref<string>;
  authTokenExpires: Ref<number>;
  authTokenExpiresIn: Ref<number>;
  authTokenCreated: Ref<number>;
  roles: Ref<string[]>;
  isAdmin: Ref<boolean>;
  isUser: Ref<boolean>;
  isAuthenticated: Ref<boolean>;
  isAuthorized: Ref<boolean>;
  portalUsername: Ref<string>;
  portalUserId: Ref<string>;
  portalDomainName: Ref<string>;
  portalDomainId: Ref<string>;
  portalSlug: Ref<string>;
  organizationId: Ref<string>;
  state: unknown;
  authenticateApp: Function;
  portalLogin: Function;
  getState: Function;
  appLogout: Function;
  resetState: Function;
}

export enum AuthMessage {
  LOADING = 'Loading...',
  VALIDATE = 'Validating...',
  AUTHORIZE = 'Authorizing...',
  SECURE = 'Securing...',
  LAUNCH = 'Launching....',
  COMPLETE = 'GO!',
  ERROR = 'ERROR!'
}

export interface PortalAppConfig {
  roles?: string[];
  meta?: unknown;
}

// Copy of the interfaces from backend/core/auth/auth.dto.ts

// Portal's Response to /verifyAppToken &
// payload for App's API /app/v1/auth/authorizeApp
export interface PortalAuthResponse {
  token: string;
  appId: string;
  appTitle: string;
  slug: string;
  created: number;
  expires: number;
  username: string;
  customerId: string;
  company: string;
  domain: string;
  domainId: string;
  agentId: string;
  appConfig: PortalAppConfig;
}

// App's API response to /app/v1/auth/authorizeApp
export interface JwtResponse {
  created: number;
  expires: number;
  expires_in: number;
  token: string;
  appOrganizationId?: string;
}
