var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: {
            app: "",
            clipped: "",
            value: _vm.drawer,
            "mini-variant": !_vm.drawer,
            width: _vm.sidebarWidth
          }
        },
        [
          _c(
            "v-layout",
            {
              attrs: {
                "fill-height": "",
                "flex-column": "",
                "justify-start": ""
              }
            },
            [
              _c(
                "v-list",
                [
                  _c("nav-drawer-menu", {
                    attrs: { drawer: _vm.drawer, data: _vm.appMenuData }
                  })
                ],
                1
              ),
              _vm.isAdmin
                ? _c(
                    "v-list",
                    [
                      _c("nav-drawer-menu", {
                        attrs: { drawer: _vm.drawer, data: _vm.adminMenuData }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-list",
                { staticClass: "mt-auto" },
                [
                  _c("v-divider"),
                  _c("ref-version", { staticClass: "text-center mb-0 mt-4" })
                ],
                1
              )
            ],
            1
          ),
          _c("FeedbackFormModal", {
            attrs: { feedback: _vm.feedback },
            on: {
              "update:feedback": function($event) {
                _vm.feedback = $event
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }