<template>
  <div style="text-align: center">
    <img :src="maintenanceImage" style="width: 25%" />
    <p>We are hard at work updating The Configurator.<br />Please check back soon!</p>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api';
export default defineComponent({
  name: 'MaintenanceMode',
  setup() {
    const maintenanceImage = computed(() => {
      return require('@/assets/images/maintenance.gif');
    });

    return {
      maintenanceImage
    };
  }
});
</script>
