export default [
  {
    path: 'docs',
    name: 'DocsList',
    component: () =>
      import(/* webpackChunkName: "organizations" */ '@/views/admin/docs/DocsList.vue')
  },
  {
    path: 'organizations',
    name: 'OrganizationsList',
    component: () =>
      import(
        /* webpackChunkName: "organizations" */ '@/views/admin/organizations/OrganizationsList.vue'
      )
  }
];
