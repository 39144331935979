var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { attrs: { "justify-center": "", row: "" } },
    [
      _c(
        "v-dialog",
        { attrs: { value: _vm.feedback, "max-width": "500" } },
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c("v-card-title", { staticClass: "headline mb-2" }, [
                _vm._v("Got Feedback?")
              ]),
              _c("v-card-subtitle", { staticClass: "mb-2 font-weight-black" }, [
                _vm._v(
                  " This message will be logged and sent immediately without confirmation. "
                )
              ]),
              _c(
                "v-card-text",
                [
                  _c("v-textarea", {
                    attrs: {
                      label: "Your Feedback",
                      name: "input-7-4",
                      outlined: "",
                      placeholder: ""
                    },
                    model: {
                      value: _vm.message,
                      callback: function($$v) {
                        _vm.message = $$v
                      },
                      expression: "message"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "white--text" },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error", outlined: "", text: "" },
                      nativeOn: {
                        click: function($event) {
                          return _vm.cancel.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "green darken-1",
                        outlined: "",
                        text: ""
                      },
                      nativeOn: {
                        click: function($event) {
                          return _vm.send.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("SEND!")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }