import VueLogger from 'vuejs-logger';
import Vue from 'vue';

const isProduction =
  process.env.VUE_APP_BUILD_ENV === 'production' || process.env.NODE_ENV === 'production';

const options = {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
};

// Bug in vuejs-logger with Vue CLI+TS
// https://github.com/justinkames/vuejs-logger/issues/38
// @ts-ignore
/* eslint-disable @typescript-eslint/no-explicit-any */
Vue.use(VueLogger as any, options);
