






import { defineComponent } from '@vue/composition-api';
import { provideToast } from 'vue-toastification/composition';
import { useGeneral } from '@/module/general';

import 'vue-toastification/dist/index.css';

import Vue from 'vue';
export default defineComponent({
  setup() {
    const { appVersionName } = useGeneral();

    provideToast({ timeout: 3000 });
    Vue.$log.debug(`${appVersionName.value} Loaded.`);
  }
});
