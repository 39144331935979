import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import appTheme from '@/styles/theme';
import 'font-awesome/css/font-awesome.css';

Vue.use(Vuetify);

export default new Vuetify({
  theme: appTheme
});
