<template>
  <v-layout justify-center row>
    <v-dialog :value="feedback" max-width="500">
      <v-card flat>
        <v-card-title class="headline mb-2">Got Feedback?</v-card-title>
        <v-card-subtitle class="mb-2 font-weight-black">
          This message will be logged and sent immediately without confirmation.
        </v-card-subtitle>
        <v-card-text>
          <v-textarea
            label="Your Feedback"
            name="input-7-4"
            outlined
            placeholder
            v-model="message"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="white--text">
          <v-btn @click.native="cancel" color="error" outlined text>Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn @click.native="send" color="green darken-1" outlined text>SEND!</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import Vue from 'vue';
import '@/lib/log';

import { defineComponent, ref } from '@vue/composition-api';
import axios from 'axios';

import { useGeneral } from '@/module/general';
import { useAuth } from '@/module/auth';
import { useUi } from '@/module/ui';

export default defineComponent({
  name: 'FeedbackFormModal',
  setup() {
    const message = ref('');
    const { appVersionName } = useGeneral();
    const { portalUsername, portalDomainName } = useAuth();
    const { feedback } = useUi();

    const send = async () => {
      const params = {
        from: `${portalUsername.value}/${portalDomainName.value}`,
        version: `${appVersionName.value}`,
        message: message.value
      };

      try {
        Vue.$log.debug('Sending feedback: ', params);
        await axios.post('feedback', params);
        await axios.post('feedback/slack', params);
      } catch (err) {
        Vue.$log.error('Trouble sending feedback. ', err);
      } finally {
        feedback.value = false;
      }
    };

    const cancel = () => {
      feedback.value = false;
    };

    return { message, send, cancel, feedback };
  }
});
</script>
