var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "launcher text-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("v-progress-circular", {
            attrs: { indeterminate: "", color: "primary" }
          })
        ],
        1
      ),
      _c("br"),
      _c("pre", [_vm._v(_vm._s(_vm.authMessage || "Loading..."))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }