<template>
  <v-container>
    <NotAuthorized />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import NotAuthorized from '@/views/NotAuthorized.vue';

export default defineComponent({
  name: 'NotAuthorizedLayout',
  components: { NotAuthorized }
});
</script>

<style lang="scss"></style>
