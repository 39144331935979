// Also need to set these in styles/variables.scss
export default {
  dark: false,
  themes: {
    dark: {
      primary: '#000000',
      accent: '#A2A2A2',
      secondary: '#6A6A6A',
      success: '#4CAF50',
      info: '#E9E9E9',
      warning: '#A56514',
      error: '#AC0000'
    },
    light: {
      primary: '#002E5C',
      accent: '#5B00D7',
      secondary: '#0034BD',
      success: '#007E05',
      info: '#000000',
      warning: '#FFB531',
      error: '#C20000'
    }
  }
};
