<template>
  <div>
    <template v-for="(item, i) in data">
      <v-row v-if="item.heading" :key="item.heading" align="center">
        <v-col cols="6">
          <span
            class="text-body-1 subheader pl-4"
            :class="item.heading && drawer ? 'show ' : 'hide'"
          >
            {{ item.heading }}
          </span>
        </v-col>
        <v-spacer :class="item.heading && drawer ? 'show ' : 'hide'"></v-spacer>
        <!-- <v-col cols="6" class="text-center"> </v-col> -->
      </v-row>
      <v-divider v-else-if="item.divider" :key="i" dark class="my-4"></v-divider>
      <v-list-group
        color="primary"
        v-else-if="item.children && drawer"
        :key="item.title"
        v-model="item.model"
        append-icon=""
      >
        <template v-slot:prependIcon>
          <v-icon :color="item.iconColor ? item.iconColor : ''">{{ item.icon }}</v-icon>
        </template>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.title }}
            </v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:appendIcon>
          <v-icon>mdi-menu-down</v-icon>
        </template>

        <v-list-item
          v-for="(child, i) in item.children"
          :key="i"
          :to="child.route && child.route.name ? child.route : undefined"
          :link="child.route && child.route.name ? true : false"
          @click="child.click ? handleClick($event, child) : null"
          exact
        >
          <v-list-item-action v-if="child.icon">
            <v-icon :color="child.iconColor ? child.iconColor : ''">{{ child.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ child.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
      <v-list-item
        color="primary"
        v-else
        :key="item.text"
        :to="item.route && item.route.name ? item.route : undefined"
        :link="item.route && item.route.name ? true : false"
        @click="item.click ? handleClick($event, item) : null"
        exact
      >
        <v-list-item-action>
          <v-icon :color="item.iconColor ? item.iconColor : ''">{{ item.icon }}</v-icon>
        </v-list-item-action>
        <v-list-item-content>
          <v-list-item-title link>
            {{ item.title }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import { useUi } from '@/module/ui';

export default defineComponent({
  name: 'NavDrawerMenu',
  props: {
    data: Array,
    drawer: Boolean
  },
  setup() {
    const { feedbackToggle } = useUi(); // eslint-disable-line

    const handleClick = (_, item) => {
      if (item.function) {
        // TODO: I don't like this. It feels like the late 90's.
        eval(`${item.function}()`);
      }
    };
    return { handleClick };
  }
});
</script>
