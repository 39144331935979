export default [
  { title: 'Dashboard', icon: 'mdi-home', route: { name: 'AppHome' } },

  // {
  //   title: 'Samples',
  //   icon: 'mdi-note-multiple-outline',
  //   iconColor: 'primary',
  //   route: undefined,
  //   children: [
  //     {
  //       title: 'Type & Spacing',
  //       icon: 'mdi-circle-small',
  //       iconColor: 'warning',
  //       route: { name: 'SampleTypography' }
  //     },
  //     { title: 'Notifications', icon: 'mdi-circle-small', route: { name: 'SampleNotifications' } },
  //     { title: 'Data Tables', icon: 'mdi-circle-small', route: { name: 'SampleDataTables' } },
  //     { title: 'Icons', icon: 'mdi-circle-small', route: { name: 'SampleIcons' } }
  //   ]
  // },
  { divider: true }
];
