var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app",
    [
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: false,
            value: _vm.wait,
            opacity: 0.75,
            "z-index": "999"
          }
        },
        [
          _c("v-progress-circular", {
            attrs: { size: 150, width: 10, color: "#eeeeff", indeterminate: "" }
          })
        ],
        1
      ),
      _c("AppNavDrawer"),
      _c("AppTopBar"),
      _c("v-main", [_c("v-container", [_c("router-view")], 1)], 1)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }