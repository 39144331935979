<template>
  <v-container>
    <NotFound />
  </v-container>
</template>

<script>
import { defineComponent } from '@vue/composition-api';

import NotFound from '@/views/NotFound.vue';

export default defineComponent({
  name: 'NotFoundLayout',
  components: { NotFound }
});
</script>

<style lang="scss"></style>
