export default [
  {
    path: 'samples',
    name: 'Samples',
    component: () => import(/* webpackChunkName: "samples" */ '@/views/samples/SampleLayout.vue'),
    children: [
      {
        path: 'typography',
        name: 'SampleTypography',
        component: () =>
          import(/* webpackChunkName: "samples" */ '@/views/samples/SampleTypography.vue')
      },
      {
        path: 'notifications',
        name: 'SampleNotifications',
        component: () =>
          import(/* webpackChunkName: "samples" */ '@/views/samples/SampleNotifications.vue')
      },
      {
        path: 'tables',
        name: 'SampleDataTables',
        component: () =>
          import(/* webpackChunkName: "samples" */ '@/views/samples/SampleDataTables.vue')
      },
      {
        path: 'icons',
        name: 'SampleIcons',
        component: () => import(/* webpackChunkName: "samples" */ '@/views/samples/SampleIcons.vue')
      },
      {
        path: '*',
        redirect: {
          name: 'NotFound'
        }
      }
    ]
  }
];
